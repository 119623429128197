<template>
  <div class="fd-w-full">
    <h1 class="fd-text-lg fd-font-bold fd-text-black">{{ $t('invitations.rs.confirmTitle') }}</h1>
    <div class="fd-block fd-mb-12">
      <span class="fd-inline-block fd-mr-2 fd-text-sm fd-font-normal" style="color: rgba(0, 0, 0, 0.71);">
        {{ $t('invitations.rs.confirmDescription') }}
      </span>
      <span>admin@sample.com</span>
      <p class="fd-text-sm fd-font-normal fd-mt-1" style="color: #6E6B7B">
        {{ $t('auth.typeYour6DigitSecurityCode') }}
      </p>
    </div>

    <b-form
      class="auth-login-form mt-1"
      @submit.prevent
    >
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator=""
        :num-inputs="6"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-change="handleOnChange"
      />
      <div class="fd-flex fd-items-center fd-justify-start fd-mt-4 fd-cursor-pointer">
        <span class="fd-font-normal fd-text-base fd-text-theme-6">{{ $t('auth.DontReceiveCode') }}</span>
        <div class="fd-ml-5 fd-flex fd-items-center">
          <span class="fd-font-normal fd-text-base fd-text-theme-4">{{ $t('auth.resend') }}</span>
        </div>
      </div>

      <div class="fd-absolute fd-bottom-12 fd-right-12">
        <b-button
          type="submit"
          variant="primary"
          style="padding-left: 40px !important; padding-right: 40px !important;"
          @click="validationForm"
        >
          {{ $t('global.next') }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { BForm, BButton } from 'bootstrap-vue'

export default {
  name: "Confirm",
  components: { BForm, BButton },
  methods: {
    validationForm() {
      if (!this.otpCompleted) return
      console.log(this.otp)
    },
    handleOnChange(value) {
      const otp = value.split('')
      if (otp.length === 6) {
        this.otp = value
        this.otpCompleted = true
        return
      }

      this.otpCompleted = false
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .otp-input {
  width: 60px;
  height: 60px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid #D8D6DE;
  text-align: center;
  outline: none;

  &:focus {
    border: 1px solid #202A21;
  }

  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>