import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=55a6e430&scoped=true&"
import script from "./Confirm.vue?vue&type=script&lang=js&"
export * from "./Confirm.vue?vue&type=script&lang=js&"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=55a6e430&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a6e430",
  null
  
)

export default component.exports